import { useTheme } from '@emotion/react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Table, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAssignmentVendor } from '../../../store/slices/pfasSlice';
import RightArrow from '../../../components/icons/RightArrow';
import { LabelContainer } from '../../financeModule/studySetup/StudySummary';
import CustomCard from '../../../components/@extended/CustomCard';
import CustomButton from '../../../components/@extended/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import { CustomTableHead, CustomTableHeadCell } from '../../../components/@extended/CustomTable';
import formData from './ProductCrfForms.json';
import DynamicFormEngine from '../../dynamicFormEngine';
import { selectCurrentUser } from '../../../store/slices/authSlice';

const productTableColumns = [
  {
    id: 'srNo',
    label: 'Sr. No.',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'productDetails.productNumber',
    label: 'Product Number',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'productDetails.productName',
    label: 'Product Name',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'productDetails.productType',
    label: 'Product type',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'productDetails.productModel',
    label: 'Product Model',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },

  // {
  //   id: 'createdAt',
  //   label: 'Created Date',
  //   style: {
  //     minWidth: 170,
  //     textAlign: 'center',
  //   },
  //   align: 'center',
  // },
  {
    id: 'rightArrow',
    label: '',
    style: {
      minWidth: 50,
      textAlign: 'right',
    },
    align: 'right',
  },
];



const VendorDetails = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { sponsorId, assignmentId, vendorId } = useParams();
  const [readOnlyFormDetails, setReadOnlyFormDetails] = useState({
    title: '',
    step: ''
  })
  const [openForm, setOpenForm] = useState(false);

  const vendorDetails = useSelector((state) => getAssignmentVendor(state, sponsorId, assignmentId, vendorId));
  const user = useSelector((state) => selectCurrentUser(state));
  const userRole = user?.details[0][0]?.role;


  const viewReadOnlyForm = (title, step) => {
    setReadOnlyFormDetails({
      title, step
    })
    setOpenForm(true)
  }
  return (
    <>
      <Grid container p={2} rowGap={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button type="text" onClick={() => navigate(-1)} startIcon={<RightArrow leftArrow />}>
              <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
                Back to Assignment
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item sm={12}>
          <CustomCard title="Vendor Details" sx={{ pb: 0 }}>
            <Grid container rowGap={1} pt={2}>
              <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
                <LabelContainer label={'Vendor Name'} value={vendorDetails?.vendorName} />
              </Grid>
              <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
                <LabelContainer label={'Email'} value={vendorDetails?.contactEmail} />
              </Grid>
              <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
                <LabelContainer label={'Contact Number'} value={vendorDetails?.contactNumber} />
              </Grid>
              <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
                <LabelContainer label={'Fax'} value={vendorDetails?.fax} />
              </Grid>
              <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
                <LabelContainer label={'Vendor Type'} value={vendorDetails?.vendorType} />
              </Grid>
              <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
                <LabelContainer
                  label={'Address'}
                  value={
                    vendorDetails?.addressLine1 +
                      ', ' +
                      vendorDetails?.city +
                      ', ' +
                      vendorDetails?.state +
                      ', ' +
                      vendorDetails?.country +
                      ' - ' +
                      vendorDetails?.postalcode || 'N/A'
                  }
                />
              </Grid>
            </Grid>
          </CustomCard>
        </Grid>
        <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'flex-end',columnGap: 2, }}>
          {formData &&
          formData?.steps?.find((_obj) => _obj.key == 'InstructionsAndGuidance') && (
            <Box>
              <CustomButton
                variant="contained"
                onClick={() => viewReadOnlyForm('Additional Info', 'InstructionsAndGuidance')}
              >
                <Typography variant="subtitle1" color={theme.palette.common.white}>
                  Additional Info
                </Typography>
              </CustomButton>
            </Box>
          )}
          <CustomButton
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              navigate(`/pfasTrak/${sponsorId}/assignment/${assignmentId}/vendor/${vendorId}/product/addProduct`);
            }}
          >
            <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
              Create Product
            </Typography>
          </CustomButton>
        </Grid>
        <Grid item sm={12}>
          <CustomCard subHeader={`Total: ${vendorDetails?.products?.length}`} title="Products" sx={{ pb: 0 }}>
            <TableContainer
              sx={{
                border: `1px ${theme.palette.grey[100]} solid `,
              }}
            >
              <Table stickyHeader>
                <CustomTableHead>
                  {productTableColumns &&
                    productTableColumns.map((column) => {
                      return (
                        <CustomTableHeadCell key={column.id} align={column.align} width={column.minWidth}>
                          {column.label}
                        </CustomTableHeadCell>
                      );
                    })}
                </CustomTableHead>
                {vendorDetails?.products?.length === 0 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={productTableColumns?.length}>
                      <Typography variant="subtitle1" color="initial">
                        No Products Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  vendorDetails?.products.map((product, rowIndex) => {
                    return (
                      <TableRow
                        key={product?.id}
                        onClick={() => {
                          navigate(
                            `/pfasTrak/${sponsorId}/assignment/${assignmentId}/vendor/${vendorId}/product/productCRFs/${product.id}`
                          );
                        }}
                        sx={{ cursor: 'pointer' }}
                      >
                        {productTableColumns?.map((col, index) => {
                          let value = product[col.id];
                          let style = {};
                          switch (col.id) {
                            case 'srNo':
                              value = rowIndex + 1;
                              break;
                            case 'status': {
                              style.fontWeight = 600;
                              if (product.status == 'Created') {
                                style.color = 'green';
                              } else {
                                style.color = 'orange';
                              }
                              value = product.status;
                              break;
                            }
                            default:
                              if (col.id.includes('.')) {
                                const colIds = col.id.split('.');
                                value = product[colIds[0]][colIds[1]];
                                console.log(`VALUE: ${value}, col.id: ${col.id}, product: ${product}`);
                              } else {
                                value = product[col.id];
                              }
                              break;
                          }
                          return col.id !== 'rightArrow' ? (
                            <TableCell key={col.id + index} align={col?.align} sx={style}>
                              {value}
                            </TableCell>
                          ) : (
                            <TableCell align="center">
                              <RightArrow />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                )}
              </Table>
            </TableContainer>
          </CustomCard>
        </Grid>
        <Dialog
            scroll={"paper"}
            maxWidth={'md'}
            open={openForm}
            onClose={() => setOpenForm(false)}
        >
            <DialogTitle>
                {readOnlyFormDetails.title}
            </DialogTitle>
            <DialogContent>
                <DynamicFormEngine
                    formConfig={formData?.steps?.find((_obj) => _obj.key == 'InstructionsAndGuidance')}
                    userSiteTrialRole={userRole}
                    showSkeleton={true}
                    hideTab
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => setOpenForm(false)}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default VendorDetails;
