import { Box, Button, CardContent, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RightArrow from "../../components/icons/RightArrow";
import TrialSiteDetailsCard from "./TrialSiteDetailsCard";
import SiteClinicalTeamCard from "./SiteClinicalTeamCard";
import TrialSiteBudgetCard from "./TrialSiteBudgetCard";
import TrialSiteCurrentExpenditure from "./TrialSiteCurrentExpenditure";
import CustomCard from "../../components/@extended/CustomCard";
import SiteStatusStepper from "./SiteStatusStepper";
import { bloqcibeApi, useDownloadVisitCsvMutation, useGetLibraryFormQuery, useGetTrialSiteInfoQuery } from "../../store/slices/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash'
import CustomButton from "../../components/@extended/CustomButton";
import SubjectListCard from "./SubjectListCard"
import DocumentsList from "../../components/common/DocumentsList";
import SubjectWithdrawListCard from "./SubjectWithdrawListCard";
import DocumentUploadWithCategory from "../../components/common/DocumentUploadWithCategory";
import ReadonlyFormDetails from "./ReadonlyFormDetails";
import SubjectAEListCard from "./SubjectAEListCard";
import AuditExportButton from "../../components/common/AuditExportButton";
import DownloadAEReport from "../../components/common/DownloadAEReport";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SearchIcon from "@mui/icons-material/Search";
import { openBackdropLoading } from "../../store/slices/applicationSlice";
import { openMessage } from "../../store/slices/showMessageSlice";
import ConfirmationDialog from "../../components/common/ConfirmationDialog";

const TrialSiteDetails = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { trialId, trialSiteId } = useParams();
  const [openICForm, setOpenICForm] = useState(false);
  const [readOnlyFormDetails, setReadOnlyFormDetails] = useState({
    title: '',
    step: ''
  })
  const [searchValue, setSearchValue] = useState('');
  const [openAeDownload, setOpenAeDownload] = useState(false);
  const crfDocumentKey = useSelector((state) => state?.trial?.trialSiteDetail?.siteTrialData?.trial?.crfForm);
  const sponsorId = useSelector((state) => state.auth.sponsorId);
  const loggedInUser = useSelector((state) => state.auth.user);
  const [uploadDocModal, setUploadDocModal] = useState(false)
  const [getLibraryForm] = bloqcibeApi.endpoints.getLibraryForm.useLazyQuery();
  const [getTrialSiteInfo] = bloqcibeApi.endpoints.getTrialSiteInfo.useLazyQuery();
  const [formData, setFormData] = useState(null)
  const enrolmentDocumentKey = useSelector((state) => state?.trial?.trialSiteDetail?.siteTrialData?.trial?.eConsentForm);
  const [trialSiteData, setTrialSiteData] = useState(null);
  const dispatch = useDispatch();
  const [downloadVisitCsv] = useDownloadVisitCsvMutation()

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    message: (
      <>
        <span>Are you sure you want to download this file?</span>
      </>
    ),
    buttonLabel: 'Download',
  });

  useEffect(() => {
    (async () => {
    const res = await getTrialSiteInfo({
        sponsorId: sponsorId,
        trialId: trialId,
        siteTrialId: trialSiteId,
    });
      setTrialSiteData(res?.data);
    })();
  }, []);

  useEffect(()=>{
    (async()=>{
      if(enrolmentDocumentKey){
        const data = await getLibraryForm(
          `${process.env.REACT_APP_API_ENDPOINT_URL}form-library/library/bloqcube/${enrolmentDocumentKey}`
        );
        if(data?.data){
          setFormData(data?.data)
        }
        else if(data?.error){
          console.error('UNABLE TO GET FORM DETAILS');
        }
      }
    })();
  }, [enrolmentDocumentKey])

  const siteInitianDocumentKey = useSelector(
    (state) => state?.trial?.trialSiteDetail?.siteTrialData?.trial?.siteInitiationForm
  );
  console.log("trialSiteData",trialSiteData)
  const siteId = trialSiteData?.siteTrialData?.siteId;
  const siteName = trialSiteData?.siteTrialData?.site?.orgname;
  const currentUserRoleOfTrailSite = useMemo(() => {
    if (trialSiteData) {
      const users = trialSiteData.users;
      const logginUserEmail = loggedInUser.email;
      const userObject = _.find(users, (user) => {
        return user.user.email?.toLowerCase() == logginUserEmail?.toLowerCase();
      })

      if (userObject) {
        return userObject.role;
      }
      return "sponsor";
    }

  }, [trialSiteData]);
  const viewReadOnlyForm = (title, step) => {
    setReadOnlyFormDetails({
      title, step
    })
    setOpenICForm(true)
  }

  const pageSize = useMemo(() => {
    if (currentUserRoleOfTrailSite == 'site_admin' ||
      currentUserRoleOfTrailSite == 'sponsor' || currentUserRoleOfTrailSite == 'site_monitor') {
      return 7;
    }
    return 12;
  }, [currentUserRoleOfTrailSite]);

  const handleOpenDocumentModal = () => {
    setUploadDocModal(true)
  }

  const downloadVisitData = async () => {
    setConfirmDialog({
      ...confirmDialog,
      open: false,
    });
    dispatch(openBackdropLoading({ backdropLoading: true }));

    try {
      const response = await downloadVisitCsv({
        sponsorId,
        trialId,
        siteId,
      }).unwrap();

      // Assuming response.data contains the CSV text
      const csvData = response;

      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const now = new Date();
      const date = now.toLocaleDateString().replace(/\//g, '-');
      const time = now.toLocaleTimeString().replace(/:/g, '-');
      const dateTime = `${date}_${time}`;
      const protocolNumber = trialSiteData?.siteTrialData?.trial?.protocolNumber || '';
      const orgName = trialSiteData?.siteTrialData?.site?.orgname || '';
      const fileTypeExtension = 'csv';
      const filename = `${protocolNumber ? protocolNumber + '_' : ''}${
        orgName ? orgName + '_' : ''
      }${dateTime}.${fileTypeExtension}`;

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      dispatch(openBackdropLoading({ backdropLoading: false }));
      dispatch(
        openMessage({
          messageSeverity: 'success',
          message: 'Document downloaded successfully!',
        })
      );
    } catch (error) {
      dispatch(openBackdropLoading({ backdropLoading: false }));
      dispatch(
        openMessage({
          messageSeverity: 'error',
          message: 'Download Failed!',
        })
      );
      console.error('Error downloading visit data file:', error);
    }
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialog({
      ...confirmDialog,
      open: false,
    });
  };

  return (
    <>
      <Grid container rowGap={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button type="text" onClick={() => navigate(-1)} startIcon={<RightArrow leftArrow />}>
              <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
                Back to Study Details
              </Typography>
            </Button>
            {currentUserRoleOfTrailSite === 'site_monitor' || currentUserRoleOfTrailSite === 'sponsor' ? (
              <AuditExportButton
                protocolNumber={trialSiteData?.siteTrialData?.trial?.protocolNumber}
                siteName={trialSiteData?.siteTrialData?.site?.orgname}
              />
            ) : (
              ''
            )}
          </Box>
        </Grid>
        <Grid container direction={'row'} columnSpacing={3}>
          <Grid item sm={12} md={pageSize}>
            <Box sx={{ pb: 3 }}>
              <TrialSiteDetailsCard siteDetails={trialSiteData?.siteTrialData} />
            </Box>
            <Box sx={{ pb: 3 }}>
              <SiteClinicalTeamCard
                teamMembers={trialSiteData?.users}
                currentUserRoleOfTrailSite={currentUserRoleOfTrailSite}
                trialId={trialId}
                siteId={siteId}
                siteName={siteName}
              />
            </Box>
            {(currentUserRoleOfTrailSite == 'site_admin' ||
              currentUserRoleOfTrailSite == 'sponsor' ||
              currentUserRoleOfTrailSite == 'site_monitor') && (
              <Box sx={{ pb: 3 }}>
                <CustomCard
                  title="eISF Documents"
                  action={
                    currentUserRoleOfTrailSite == 'site_admin' && (
                      <CustomButton size="small" onClick={handleOpenDocumentModal} startIcon={<CloudUploadIcon />}>
                        Upload Documents
                      </CustomButton>
                    )
                  }
                >
                  <DocumentsList trialId={trialId} sponsorId={sponsorId} siteId={siteId} />
                </CustomCard>
              </Box>
            )}
            {siteInitianDocumentKey !== 'NA' && (
              <Box sx={{ pb: 3 }}>
                <SiteStatusStepper />
              </Box>
            )}
          </Grid>
          {(currentUserRoleOfTrailSite == 'site_admin' ||
            currentUserRoleOfTrailSite == 'sponsor' ||
            currentUserRoleOfTrailSite == 'site_monitor') && (
            <Grid item md={5} sm={12}>
              <Box sx={{ pb: 3 }}>
                <TrialSiteBudgetCard siteDetails={trialSiteData?.siteTrialData} />
              </Box>
              <Box sx={{ pb: 3 }}>
                <TrialSiteCurrentExpenditure siteDetails={trialSiteData?.siteTrialData} />
              </Box>
            </Grid>
          )}
        </Grid>
        {(currentUserRoleOfTrailSite == 'PI' ||
          currentUserRoleOfTrailSite == 'site_coordinator' ||
          currentUserRoleOfTrailSite == 'site_monitor') &&
          trialSiteData?.siteTrialData?.status == 'Completed' && (
            <Grid container rowSpacing={4.5} justifyContent={'space-between'}>
              {currentUserRoleOfTrailSite === 'site_monitor' && (
                <Grid item>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Search Subject</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={'text'}
                      onChange={(e) => setSearchValue(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            // onClick={handleSearch}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Search Subject"
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid
                item
                sm={currentUserRoleOfTrailSite !== 'site_monitor' ? 12 : ''}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  columnGap: 2,
                  alignItems:'center'
                }}
              >
                {(currentUserRoleOfTrailSite == 'PI' ||
                  currentUserRoleOfTrailSite == 'site_monitor' ||
                  currentUserRoleOfTrailSite == 'site_coordinator') &&
                  formData &&
                  formData?.steps?.find((_obj) => _obj.key == 'InstructionsAndGuidance') && (
                    <Box>
                      <CustomButton
                        variant="contained"
                        onClick={() => viewReadOnlyForm('Additional Info', 'InstructionsAndGuidance')}
                      >
                        <Typography variant="subtitle1" color={theme.palette.common.white}>
                          Additional Info
                        </Typography>
                      </CustomButton>
                    </Box>
                  )}
                {(currentUserRoleOfTrailSite == 'PI' ||
                  currentUserRoleOfTrailSite == 'site_monitor' ||
                  currentUserRoleOfTrailSite == 'site_coordinator') && (
                  <Box>
                    <CustomButton variant="contained" onClick={() => viewReadOnlyForm('IC Form Details', 'IC')}>
                      <Typography variant="subtitle1" color={theme.palette.common.white}>
                        View IC Form
                      </Typography>
                    </CustomButton>
                  </Box>
                )}
                {currentUserRoleOfTrailSite === 'site_monitor' && (
                  <Box>
                    <CustomButton
                      sx={{ mr: 2 }}
                      variant="contained"
                      onClick={() => {
                        setOpenAeDownload(true);
                      }}
                    >
                      <Typography variant="subtitle1" color={theme.palette.common.white}>
                        AE/SAE Report
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      onClick={() =>
                        setConfirmDialog({
                          ...confirmDialog,
                          open: true,
                        })
                      }
                    >
                      <Typography variant="subtitle1" color={theme.palette.common.white}>
                        Export
                      </Typography>
                    </CustomButton>
                  </Box>
                )}
                {(currentUserRoleOfTrailSite == 'PI' || currentUserRoleOfTrailSite == 'site_coordinator') && (
                  <CustomButton
                    variant="contained"
                    onClick={() => {
                      navigate(`/eConcent/${trialId}/trial-site/${trialSiteId}/SubjectEnrollmentFormParent`);
                    }}
                  >
                    <Typography variant="subtitle1" color={theme.palette.common.white}>
                      + Screen Subject
                    </Typography>
                  </CustomButton>
                )}
              </Grid>
              <Grid item sm={12} sx={{ paddingTop: 5 }}>
                {currentUserRoleOfTrailSite === 'site_monitor' && (
                  <SubjectAEListCard
                    searchValue={searchValue}
                    trialId={trialId}
                    siteId={siteId}
                    trialSiteId={trialSiteId}
                  />
                )}
                {currentUserRoleOfTrailSite !== 'site_monitor' && (
                  <SubjectListCard
                    teamMembers={trialSiteData?.users}
                    trialId={trialId}
                    siteId={siteId}
                    trialSiteId={trialSiteId}
                    setOpenAeDownload={setOpenAeDownload}
                  />
                )}
              </Grid>
              <Grid item sm={12} sx={{ paddingTop: 8 }}>
                <SubjectWithdrawListCard
                  sponsorId={sponsorId}
                  trialId={trialId}
                  siteId={siteId}
                  trialSiteId={trialSiteId}
                />
              </Grid>
            </Grid>
          )}
      </Grid>
      <DocumentUploadWithCategory
        dialogTitle={'Upload eISF Documents'}
        open={uploadDocModal}
        handleClose={() => setUploadDocModal(false)}
        trialId={trialId}
        siteId={siteId}
      />
      <ReadonlyFormDetails
        title={readOnlyFormDetails.title}
        open={openICForm}
        trialId={trialId}
        sponsorId={sponsorId}
        trialSiteId={trialSiteId}
        step={readOnlyFormDetails.step}
        handleClose={() => setOpenICForm(false)}
      />
      <ConfirmationDialog
        open={confirmDialog.open}
        buttonLabel={confirmDialog.buttonLabel}
        message={confirmDialog.message}
        handleClose={handleConfirmDialogClose}
        handleConfirm={downloadVisitData}
      />
      <DownloadAEReport
        open={openAeDownload}
        handleClose={() => setOpenAeDownload(false)}
        trialId={trialId}
        siteId={siteId}
        documentKey={crfDocumentKey}
      />
    </>
  );
};

export default TrialSiteDetails;
