import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Box, Typography, useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const VideoEmbedField = ({ s3Key, link }) => {
  const theme = useTheme();

  const [videoUrl, setVideoUrl] = useState(link);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchVideo = async () => {
      if (link) {
        setVideoUrl(link);
      } else if (s3Key) {
        try {
          // Fetch the video from the backend
          // const response = await axios.get(`YOUR_BACKEND_API_URL/videos/${s3Key}`, {
          //   responseType: 'blob',
          // });
          // const url = URL.createObjectURL(response.data);
          // setVideoUrl(url);
        } catch (error) {
          console.error('Error fetching the video', error);
        }
      }
    };

    fetchVideo();

    // Cleanup the URL object when the component unmounts
    return () => {
      if (videoUrl && !link) {
        URL.revokeObjectURL(videoUrl);
      }
    };
  }, [s3Key, link, videoUrl]);

  const isYouTubeVideo = ReactPlayer.canPlay(videoUrl) && videoUrl.includes('youtube.com');

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        paddingTop: isYouTubeVideo ? '56.25%' : 'auto',
        minHeight: '200px', // Minimum height for the video component
        maxHeight: '500px', // Maximum height for the video component
        height: 'auto', // Allow the height to adjust automatically
        border: error ? `1px solid ${theme.palette.grey[600]}` : '',
        borderRadius: error ? 2 : 0,
      }}
    >
      {!error ? (
        <ReactPlayer
          url={videoUrl}
          controls
          width="100%"
          height="100%"
          style={{
            position: isYouTubeVideo ? 'absolute' : 'relative',
            top: 0,
            left: 0,
            minHeight: '200px',
            maxHeight: '500px',
            height: '100%',
          }}
          onError={(e) => setError(true)}
        />
      ) : (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <InfoOutlinedIcon sx={{ color: theme.palette.grey[600] }} />
          <Typography variant="h6" color={theme.palette.grey[600]} sx={{ pl: 1 }}>
            Unable to load video...
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default VideoEmbedField;
