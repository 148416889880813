import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, useTheme, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import { openSessionExpiredDialog } from '../../store/slices/applicationSlice';
import { useDispatch } from 'react-redux';
import CustomButton from '../@extended/CustomButton';

const ExpiredDialog = ({isOpen ,message}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const handleClose = () => {
    navigate('/login');
    window.location.reload(); // Reload the page after navigation
    dispatch(openSessionExpiredDialog(false))
  }
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', rowGap: 3 }}>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: 2 }}>
              <InfoOutlinedIcon sx={{ fontSize: 60, color: theme.palette.grey.A700 }}></InfoOutlinedIcon>
            </Box>
            <DialogContentText>{message}</DialogContentText>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CustomButton variant={'contained'} size="small" onClick={handleClose}>
              <Typography variant="subtitle2" color={theme.palette.common.white} fontWeight={600}>
                Ok
              </Typography>
            </CustomButton>
          </Box>
        </Box>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions> */}
    </Dialog>
  );
}

export default ExpiredDialog;
