import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { CustomTableHead, CustomTableHeadCell } from '../../components/@extended/CustomTable';
import CustomButton from '../../components/@extended/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import CreateOrganization from '../financeModule/CreateOrganization';
import RightArrow from '../../components/icons/RightArrow';

const sponsorTableColumns = [
  {
    id: 'logo',
    label: '',
    style: {
      minWidth: 5,
      maxWidth: 7,
      textAlign: 'left',
    },
    align: 'left',
  },
  {
    id: 'orgName',
    label: 'Organization Name',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'orgCategory',
    label: 'Category',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'email',
    label: 'Email',
    style: {
      minWidth: 200,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    style: {
      minWidth: 170,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'rightArrow',
    label: '',
    style: {
      minWidth: 50,
      textAlign: 'right',
    },
    align: 'right',
  },
];

const PfasTrack = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openCreateSponsor, setOpenCreateSponsor] = useState(false);
  const sponsors = useSelector((state) => state.pfas?.organizations);
  const handleCloseCreateSponsor = () => {
    setOpenCreateSponsor(false);
  };
  return (
    <>
      <Grid container p={3} rowGap={2}>
        {!openCreateSponsor ? (
          <>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" color="initial" sx={{ py: 2 }}>
                  PFAS Organizations
                </Typography>
                <Box>
                  <CustomButton variant="contained" startIcon={<AddIcon />} onClick={() => setOpenCreateSponsor(true)}>
                    Add Organization
                  </CustomButton>
                </Box>
              </Box>
            </Grid>
            <Grid container>
              <Grid item sm={12} md={12}>
                <Paper sx={{ width: '100%' }}>
                  <Box
                    sx={{
                      width: '100%',
                      height: 3,
                      backgroundColor: theme.palette.primary.light,
                      borderRadiusTop: 5,
                    }}
                  />
                  <TableContainer sx={{ maxHeight: 550 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <CustomTableHead>
                        <TableRow>
                          {sponsorTableColumns.map((column) => (
                            <CustomTableHeadCell key={column.id} align={column.align} sx={{ ...column.style }}>
                              {column.label}
                            </CustomTableHeadCell>
                          ))}
                        </TableRow>
                      </CustomTableHead>
                      {sponsors.length === 0 ? (
                        <TableRow>
                          <TableCell align="center" colSpan={sponsorTableColumns.length}>
                            <Typography variant="subtitle1" color="initial">
                              No PFAS Organization Found
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ) : (
                        sponsors.map((sponsor) => (
                          <TableRow key={sponsor.id} sx={{cursor:'pointer'}} onClick={() => navigate(`/pfasTrak/${sponsor.id}`)}>
                            <TableCell align="center">{sponsor.id}</TableCell>
                            <TableCell align="center">{sponsor.orgName}</TableCell>
                            <TableCell align="center">{sponsor.orgCategory}</TableCell>
                            <TableCell align="center">{sponsor.contactEmail}</TableCell>
                            <TableCell align="center">{sponsor.createdAt}</TableCell>
                            <TableCell align="right">
                              <RightArrow onClick={() => navigate(`/pfasTrak/${sponsor.id}`)} />
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </>
        ) : (
            <CreateOrganization open={openCreateSponsor} onClose={handleCloseCreateSponsor} type={'PFAS'} />
        )}
      </Grid>
    </>
  );
};

export default PfasTrack;
