import React from 'react';
import { Grid, Typography, Container } from '@mui/material';
import i from '../../src/assets/images/PageNotFound/notfound.jpg';
import CustomButton from '../components/@extended/CustomButton';
import { useNavigate } from 'react-router-dom';

const PageNotFound = ({ height, title }) => {
  const navigate = useNavigate();
  const goHome = () => {
    navigate('/');
  };

  return (
    <Container
      sx={{ height: height ? height : '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={7} md={6}>
          <img src={i} alt="Page Not Found" style={{ width: '100%', height: 'auto' }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h2" component="h1" gutterBottom sx={{ fontSize: { xs: '2rem', md: '3rem' } }}>
            {title ? <>OOPS! <br />{title}</> : <>OOPS! PAGE <br /> NOT FOUND</>}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            You must have picked the wrong door.
            <br />
            We haven't been able to find the page for you. Don't worry, we'll help you find your way!
          </Typography>
          <CustomButton variant="contained" color="primary" onClick={goHome} sx={{ mt: 5 }}>
            Back to Home
          </CustomButton>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PageNotFound;
