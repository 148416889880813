import { Box, Button, Card, CardContent, CardHeader, Grid, Paper, Table, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RightArrow from '../../../components/icons/RightArrow';
import { useDispatch, useSelector } from 'react-redux';
import CustomCard from '../../../components/@extended/CustomCard';
import { selectAssignmentDetails, selectCmpOffDetails } from '../../../store/slices/pfasSlice';
import { LabelContainer } from '../../financeModule/studySetup/StudySummary';
import Label from '../../../components/common/Label';
import { CustomTableHead, CustomTableHeadCell } from '../../../components/@extended/CustomTable';

const vendorColumns = [
  {
    id: 'srNo',
    label: 'Sr. No.',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'vendorName',
    label: 'Vendor Name',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'vendorType',
    label: 'Vendor Type',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'contactNumber',
    label: 'Contact Number',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'contactEmail',
    label: 'Email',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    style: {
      minWidth: 170,
      textAlign: 'center',
    },
    align: 'center',
  },
  {
    id: 'rightArrow',
    label: '',
    style: {
      minWidth: 50,
      textAlign: 'right',
    },
    align: 'right',
  },
];

const AssignmentDetails = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { sponsorId, assignmentId } = useParams();
  const assignmentDetails = useSelector((state) => selectAssignmentDetails(state, sponsorId, assignmentId));

  const complianceOfficer = useSelector((state) =>
    selectCmpOffDetails(state, sponsorId, assignmentDetails?.complianceOfficer)
  );
  return (
    <>
      <Grid container rowGap={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button type="text" onClick={() => navigate(-1)} startIcon={<RightArrow leftArrow />}>
              <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
                Back
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item sm={12}>
          <CustomCard title="Assignment Details" sx={{ pb: 0 }}>
            <Grid container rowGap={1} pt={2}>
              <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
                <LabelContainer label={'Assignment Title'} value={assignmentDetails?.assignmentTitle} />
              </Grid>
              <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
                <LabelContainer label={'Assignment Number'} value={assignmentDetails?.assignmentNumber} />
              </Grid>
              <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
                <LabelContainer label={'Assignment Version'} value={assignmentDetails?.assignmentVersion} />
              </Grid>
              <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
                <LabelContainer label={'Assignment Date'} value={assignmentDetails?.assignmentDate} />
              </Grid>
            </Grid>
          </CustomCard>
        </Grid>
        <Grid item sm={12}>
          <CustomCard title="Compliance Officer Details" sx={{ pb: 0 }}>
            <Grid container rowGap={1} pt={2}>
              <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
                <LabelContainer
                  label={'Officer Name'}
                  value={complianceOfficer?.firstName + ' ' + complianceOfficer?.lastName}
                />
              </Grid>
              <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
                <LabelContainer label={'Contact Number'} value={complianceOfficer?.contactNumber} />
              </Grid>
              <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
                <LabelContainer label={'Email'} value={complianceOfficer?.email} />
              </Grid>
              <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
                <LabelContainer
                  label={'Address'}
                  value={complianceOfficer?.address + ', ' + complianceOfficer?.pincode}
                />
              </Grid>
            </Grid>
          </CustomCard>
        </Grid>
        <Grid item sm={12}>
          <CustomCard title="Vendors" sx={{ pb: 0 }}>
            <Paper>
              <TableContainer sx={{ maxHeight: 550 }}>
                <Table stickyHeader aria-label="sticky table">
                  <CustomTableHead>
                    <TableRow>
                      {vendorColumns?.map((column) => (
                        <CustomTableHeadCell key={column.id} align={column.align} sx={{ ...column.style }}>
                          {column.label}
                        </CustomTableHeadCell>
                      ))}
                    </TableRow>
                  </CustomTableHead>
                  {assignmentDetails?.vendors.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={vendorColumns?.length}>
                        <Typography variant="subtitle1" color="initial">
                          No Vendors Found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    assignmentDetails?.vendors.map((vendor, rowIndex) => {
                      return (
                        <TableRow
                          key={vendor.id + vendor.vendorName}
                          onClick={() => {
                              navigate(`/pfasTrak/${sponsorId}/assignment/${assignmentId}/vendor/${vendor.id}`);
                          }}
                          sx={{ cursor: 'pointer' }}
                        >
                          {vendorColumns?.map((col, index) => {
                            let value = vendor[col.id];
                            let style = {};
                            switch (col.id) {
                              case 'srNo':
                                value = rowIndex + 1;
                                break;
                              case 'status': {
                                style.fontWeight = 600;
                                if (vendor.status == 'Created') {
                                  style.color = 'green';
                                } else {
                                  style.color = 'orange';
                                }
                                value = vendor.status;
                                break;
                              }
                              default:
                                value = vendor[col.id];
                                break;
                            }
                            return col.id !== 'rightArrow' ? (
                              <TableCell key={col.id + index} align={col?.align} sx={style}>
                                {value}
                              </TableCell>
                            ) : (
                              <TableCell align="center">
                                <RightArrow />
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  )}
                </Table>
              </TableContainer>
            </Paper>
          </CustomCard>
        </Grid>
      </Grid>
    </>
  );
};

export default AssignmentDetails;
