import { Box, Card, CardContent, Divider, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { LabelContainer } from '../../financeModule/studySetup/StudySummary';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectAssignmentDetails, selectCmpOffDetails, updateAssignmentStatus } from '../../../store/slices/pfasSlice';
import CustomButton from '../../../components/@extended/CustomButton';

const AssignmentSummary = ({ handleTabChange }) => {
  const { sponsorId, assignmentId } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const assignmentDetails = useSelector((state) => selectAssignmentDetails(state, sponsorId, assignmentId));

  const complianceOfficer = useSelector((state) =>
    selectCmpOffDetails(state, sponsorId, assignmentDetails?.complianceOfficer)
  );

  const handleFinish = () => {
    dispatch(updateAssignmentStatus({ orgId: sponsorId, assignmentId, status: 'Created' }));
    // navigate(`pfasTrak/${sponsorId}`,{replace:true});
    navigate(-2, {replace:true});
  };
    
  return (
    <Card>
      <CardContent>
        <Grid
          container
          sx={{
            // border: '1px solid #e7e7e7',
            padding: 2,
            borderRadius: 1,
            // backgroundColor: '#fbfbfb',
          }}
          rowSpacing={2}
        >
          <Grid item sm={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6" sx={{ fontWeight: 510 }} color={theme.palette.grey[800]}>
                Assignment Summary
              </Typography>
              <CustomButton>Export</CustomButton>
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Assignment Title'} value={assignmentDetails?.assignmentTitle} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Assignment Number'} value={assignmentDetails?.assignmentNumber} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Assignment Version'} value={assignmentDetails?.assignmentVersion} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Assignment Date'} value={assignmentDetails?.assignmentDate} />
          </Grid>
          <Grid item sm={12}>
            <Divider />
          </Grid>
          <Grid item sm={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: 2 }}>
              <Typography sx={{ fontWeight: 600, fontSize: 18 }} color={theme.palette.grey[800]}>
                Compliance Officer
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer
              label={'Officer Name'}
              value={complianceOfficer?.firstName + ' ' + complianceOfficer?.lastName}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Contact Number'} value={complianceOfficer?.contactNumber} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Email'} value={complianceOfficer?.email} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabelContainer label={'Address'} value={complianceOfficer?.address + ', ' + complianceOfficer?.pincode} />
          </Grid>
          <Grid item sm={12}>
            <Divider />
          </Grid>
          <Grid item sm={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontWeight: 600, fontSize: 18 }} color={theme.palette.grey[800]}>
                Vendors
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {assignmentDetails?.vendors?.map((vendor, index) => {
              return (
                <Box
                  sx={{
                    marginTop: index === 0 ? 0 : 3,
                    border: '1px solid #e7e7e7',
                    padding: 3,
                    borderRadius: 1,
                    backgroundColor: '#fbfbfb',
                  }}
                >
                  <Box sx={{ display: 'flex', marginBottom: 2 }}>
                    <Typography sx={{ flexGrow: 1, fontWeight: 'bold', color: theme.palette.grey.A700 }}>
                      {vendor?.vendorName}
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={2}>
                      <Typography sx={{ color: theme.palette.grey.A700 }}>{'Vendor Address:'}</Typography>
                    </Grid>
                    <Grid item sm={12} md={10}>
                      <Typography sx={{ color: 'grey', fontWeight: 'medium' }}>{vendor?.addressLine1}</Typography>
                    </Grid>
                    <Grid item sm={12} md={2}>
                      <Typography sx={{ color: theme.palette.grey.A700 }}>{'Phone number:'}</Typography>
                    </Grid>
                    <Grid item sm={12} md={10}>
                      <Typography sx={{ color: 'grey', fontWeight: 'medium' }}>{vendor?.contactNumber}</Typography>
                    </Grid>
                    <Grid item sm={12} md={2}>
                      <Typography sx={{ color: theme.palette.grey.A700 }}>{'Fax number:'}</Typography>
                    </Grid>
                    <Grid item sm={12} md={10}>
                      <Typography sx={{ color: 'grey', fontWeight: 'medium' }}>{vendor?.fax}</Typography>
                    </Grid>
                    <Grid item sm={12} md={2}>
                      <Typography sx={{ color: theme.palette.grey.A700 }}>{'Contact Email:'}</Typography>
                    </Grid>
                    <Grid item sm={12} md={10}>
                      <Typography sx={{ color: 'grey', fontWeight: 'medium' }}>{vendor?.contactEmail}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <CustomButton variant="outlined" onClick={() => handleTabChange('_', 1)}>
              Back
            </CustomButton>
            <CustomButton onClick={handleFinish}>Finish</CustomButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AssignmentSummary;
